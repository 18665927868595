import {
  CATEGORY_SET,
} from '../constants/actionTypes';

const INITIAL_STATE = {
  category: ''
};

const applySetCategory = ( state, action ) => {
  return(
    {
      category: action.category,
    }
  );
};

function categoryReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case CATEGORY_SET : {
      return applySetCategory(state, action);
    }
    default: return state;
  }
}

export default categoryReducer;
