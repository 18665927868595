import React from 'react';

import styles from './Text.module.css';
import arrowImg from '../../../../../assets/titleArrow.png';
import Aux from '../../../../../hoc/Aux';

const Text = (props) => {
  let text;
  let titles;
  switch(props.language){
    default:
    case 'nl':
      text = (props.text !== null) ? props.text.nl : null;
      titles = ['Status', 'Locatie', 'Programma'];
      break;
    case 'en':
      text = (props.text !== null) ? props.text.en : null;
      titles = ['Status', 'Location', 'Program'];
      break;
  }

  const hoverTextItemStyle = (props.isHover && props.hasLink)
    ? {
      fontWeight: 'bold'
    }
    : null

  const hoverTextItemTitleStyle = (props.isHover && props.hasLink)
    ? {
      fontWeight: '400'
    }
    : null

    const hoverTitleStyle = (props.isHover && props.hasLink)
      ? {
        fontWeight: '900'
      }
      : null

  return(
    <div className={styles.Text}>
    {
      text !== null
        ? <Aux>
            <div className={styles.titleWrapper}>
              <div style={hoverTitleStyle} className={styles.title}>{text.title}</div>
              {
                props.hasLink
                  ? <div className={styles.arrow}><img src={arrowImg} alt="" /></div>
                  : null
              }
            </div>
            <table>
              <tbody>

                <tr>
                  <td style={hoverTextItemTitleStyle} className={styles.textItemTitle}>{titles[0]}:</td>
                  <td style={hoverTextItemStyle} className={styles.textItem}>{text.status}</td>
                </tr>
                <tr>
                  <td style={hoverTextItemTitleStyle} className={styles.textItemTitle}>{titles[1]}:</td>
                  <td style={hoverTextItemStyle} className={styles.textItem}>{text.location}</td>
                </tr>
                <tr>
                  <td style={hoverTextItemTitleStyle} className={styles.textItemTitle}>{titles[2]}:</td>
                  <td style={hoverTextItemStyle} className={styles.textItem}>{text.program}</td>
                </tr>
              </tbody>
            </table>
          </Aux>
        : null
    }

    </div>
  );
}

// <tr>
//   <td className={styles.titleWrapper}>
//     <div style={hoverTitleStyle} className={styles.title}>{text.title}</div>
//     {
//       props.hasLink
//         ? <div className={styles.arrow}><img src={arrowImg} alt="" /></div>
//         : null
//     }
//   </td>
// </tr>

export default Text;
