import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import styles from './ProjectOverview.module.css';
import { WORK_CAT } from '../../../../constants/categories';
import { doFetchWorks } from '../../../../actions/post';
import { getWorks } from '../../../../selectors/post';
import { doSetCategory } from '../../../../actions/category';
import { getLanguage } from '../../../../selectors/language';
import Item from './Item/Item';

class ProjectOverview extends Component {
  componentDidMount(){
    this.props.onSetCategory(WORK_CAT);
    this.props.onFetchPosts(WORK_CAT);
  }

  render(){
    const postsOrdered = this.props.posts.sort(function (a, b) {
        return b.order - a.order;
    });
    
    const items = (postsOrdered || []).map((post, i) => {
      return <Item
                key={i}
                post={post}
                language={this.props.language} />
    });

    return(
      <div className={styles.ProjectOverview}>
        <div className={styles.wrapper}>
          {items}
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  posts: getWorks(state),
  language: getLanguage(state),
});

const mapDispatchToProps = (dispatch) => ({
  onFetchPosts: query => dispatch(doFetchWorks(query)),
  onSetCategory: category => dispatch(doSetCategory(category)),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectOverview));
