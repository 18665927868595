import React from 'react';

import styles from './LandingPage.module.css';

import img from '../../../assets/enter.png';

const EnterButton = (props) => {
  return(
    <div className={styles.enterButton}>
      <img src={img} alt="" onClick={props.clickHandler}/>
    </div>
  );
}

export default EnterButton;
