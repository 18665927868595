import React from 'react';

import styles from './Image.module.css';

const Image = (props) => {
  const style = (props.greyscale)
    ? [styles.Image, styles.greyscale].join(' ')
    : styles.Image;
    
  return(
    <div className={style}>
      <img src={props.path} alt="" />
    </div>
  );
}

export default Image;
