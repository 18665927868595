import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Swipe from 'react-easy-swipe';

import styles from './Process.module.css';
import { PROCESS_CAT } from '../../../../constants/categories';
import { doFetchProcess } from '../../../../actions/post';
import { getProcess } from '../../../../selectors/post';
import { doSetCategory } from '../../../../actions/category';
import ProcessItem from './ProcessItem/ProcessItem';
import Navigation from './Navigation/Navigation';
import FloatingTitle from './FloatingTitle/FloatingTitle';
// import Navigation from '../Navigation/Navigation';
// import Aux from '../../../../hoc/Aux';
import { SWIPE_SENS } from '../../../../constants/swipe';

class Process extends Component {
  constructor(props){
    super(props);

    this.onSwipeEnd = this.onSwipeEnd.bind(this);
    this.onSwipeMove = this.onSwipeMove.bind(this);
    this.onSwipeStart = this.onSwipeStart.bind(this);

    this.state = {
      isLoading: true,
      currPost: 0,
      isEnd: false,
      posts: [],
      isSwiping: false,
      ySwipe: []
    }
  }

  onSwipeStart(event){
    this.setState({
      isSwiping: true
    });
  }

  onSwipeMove(position, event){
    this.setState(state => {
      const ySwipe = state.ySwipe.concat(position.y);

      return {
        ySwipe
      };
    });
  }

  onSwipeEnd(event){
    if(this.state.isSwiping){
      if(this.state.ySwipe[0] - this.state.ySwipe[this.state.ySwipe.length - 1] > SWIPE_SENS){
        console.log('swipe down');
        this.postClickHandler(1);
      }

      // if(this.state.ySwipe[0] - this.state.ySwipe[this.state.ySwipe.length - 1] < -SWIPE_SENS){
      //   console.log('swipe up');
      //   this.postClickHandler(1);
      // }

      this.setState({
        isSwiping: false,
        ySwipe: []
      });
    }

  }

  componentDidMount(){
    this.props.onSetCategory(PROCESS_CAT);
    this.props.onFetchPosts('process');
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.isEnd){
      this.setState({
        posts: [...this.state.posts, ...this.shuffle([...this.props.posts])],
        isEnd: false,
      });
    }

    if(prevProps.posts !== this.props.posts && this.state.isLoading){
      this.setState({
        isLoading: false,
        posts: this.props.posts
      });
    }

    if(prevState.isLoading === true && this.state.isLoading === false){
      if(prevProps.match.isExact === false){
        const locArr = prevProps.location.pathname.split('/');
        const title = locArr[3];
        let pid = 0;
        this.props.posts.forEach((post, i) => {
          if(title === post.title.toLowerCase()){
            pid = i;
          }
        });
        this.itemRefs[pid].current.scrollIntoView();
        this.setState({
          currPost: pid,
        });
      }
    }
  }

  postClickHandler = (dir) => {
    // console.log("postclicked");
    const size = this.state.posts.length;
    let currPost = this.state.currPost;
    let isEnd = this.state.isEnd;
    if (dir === 0) {
      currPost -= 1;
      currPost = currPost < 0 ? 0 : currPost;
    } else if (dir === 1) {
      currPost += 1;
      if(currPost > size - 2){
        isEnd = true;
      }
      currPost = currPost > size - 1 ? size - 1 : currPost;
    }

    this.setState({
      currPost: currPost,
      isEnd: isEnd,
    });
  }

  shuffle = (arr) => {
    var i, j, temp;
    for (i = arr.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      temp = arr[i];
      arr[i] = arr[j];
      arr[j] = temp;
    }
    return arr;
  };

  wheelHandler = (e) => {
    // if(e.deltaY > 0) {
    //   this.postClickHandler(1);
    // }
  }

 //  <Navigation
 //     postClickHandler={this.postClickHandler}
 //     showPageNav={false}
 //     hidePrev={true}
 //     hideNext={false}
 //     wheelHandler={this.wheelHandler}
 // />
  render(){
    // console.log(this.state.posts);
    return(
      <Swipe
        className={styles.swipe}
        onSwipeStart={this.onSwipeStart}
        onSwipeMove={this.onSwipeMove}
        onSwipeEnd={this.onSwipeEnd}>
        <div className={styles.process}>
          <div className={styles.items}>
            {
              (this.state.posts || []).map((post, i) => {
                return <ProcessItem
                  key={i}
                  post={post}
                  offset={this.state.currPost * -100}
                />
              })
            }
          </div>
          {
            this.state.isLoading
              ? null
              : <FloatingTitle currTitleNl={this.state.posts[this.state.currPost].description.description_nl} currTitleEn={this.state.posts[this.state.currPost].description.description_nl} meta={null} showArrow={false} showTitle={true}/>
          }

          <Navigation clickHandler={this.postClickHandler}/>
        </div>
      </Swipe>
    );
  }
}

const mapStateToProps = state => ({
  posts: getProcess(state),
});

const mapDispatchToProps = (dispatch) => ({
  onFetchPosts: query => dispatch(doFetchProcess(query)),
  onSetCategory: category => dispatch(doSetCategory(category)),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Process));
