import React from 'react';
import { connect } from 'react-redux';

import styles from './NewsItem.module.css';
import { getLanguage } from '../../../../../selectors/language';
import Title from './Title';

const Content = (props) => {
  let text;
  let title;
  switch(props.language) {
    default:
    case 'nl':
      text = <div
                key={props.post.id}
                className={styles.text}
                dangerouslySetInnerHTML={{__html: props.post.textNl}}
              />
      title = props.post.title_nl;
      break;
    case 'en':
      text = <div
                key={props.post.id}
                className={styles.text}
                dangerouslySetInnerHTML={{__html: props.post.textEn}}
              />
              title = props.post.title_en;
      break;
  }

  const images = (Object.keys(props.post.images) || []).map((key, i) => {
    // console.log(props.post.images[key].width, props.post.images[key].height);
    const style = props.post.images[key].width > props.post.images[key].height
      ? [styles.image, styles.landscape].join(' ')
      : [styles.image, styles.portrait].join(' ');
    return <div key={key} className={style} >
              <img src={props.post.images[key].guid} alt=""/>
           </div>
  });

  return(
    <div className={styles.content}>
      {images[0]}
      <div className={styles.textWrapper}>
        <Title title={title} date={props.post.date}/>
        {text}
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  language: getLanguage(state),
});

export default connect(
  mapStateToProps
)(Content);
