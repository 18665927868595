import { call, put } from 'redux-saga/effects';
import {
  doAddContact,
  doAddWorks,
  doAddProcess,
  doAddNews,
  doAddCv,
  doAddLandingPage,
  doAddSelWorks,
} from '../actions/post';
import { fetchPosts } from '../api/post';

function* handleFetchContact(action) {
  const { query } = action;
  const result = yield call(fetchPosts, query);
  yield put(doAddContact(result));
}

function* handleFetchWorks(action) {
  const { query } = action;
  const result = yield call(fetchPosts, query);
  yield put(doAddWorks(result));
}

function* handleFetchProcess(action) {
  const { query } = action;
  const result = yield call(fetchPosts, query);
  yield put(doAddProcess(result));
}

function* handleFetchNews(action) {
  const { query } = action;
  const result = yield call(fetchPosts, query);
  yield put(doAddNews(result));
}

function* handleFetchCv(action) {
  const { query } = action;
  const result = yield call(fetchPosts, query);
  yield put(doAddCv(result));
}

function* handleFetchLandingPage(action) {
  const { query } = action;
  const result = yield call(fetchPosts, query);
  yield put(doAddLandingPage(result));
}

function* handleFetchSelWorks(action) {
  const { query } = action;
  const result = yield call(fetchPosts, query);
  yield put(doAddSelWorks(result));
}

export {
  handleFetchContact,
  handleFetchWorks,
  handleFetchProcess,
  handleFetchNews,
  handleFetchCv,
  handleFetchLandingPage,
  handleFetchSelWorks
};
