import React, { Component } from 'react';
import ReactLoading from 'react-loading';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Swipe from 'react-easy-swipe';

import styles from './LandingPage.module.css';
import { LANDING_PAGE_CAT } from '../../../constants/categories';
import { doFetchLandingPage } from '../../../actions/post';
import { getLandingPage } from '../../../selectors/post';
import { doSetCategory } from '../../../actions/category';
import EnterButton from './EnterButton';

class LandingPage extends Component {
  constructor(props){
    super(props);

    this.onSwipeEnd = this.onSwipeEnd.bind(this);
    this.onSwipeMove = this.onSwipeMove.bind(this);
    this.onSwipeStart = this.onSwipeStart.bind(this);

    this.state = {
        isLoading: true,
        id: 0,
        alpha: 0.0,
        width: window.innerWidth,
        height: window.innerHeight,
        isSwiping: false,
        ySwipe: [],
    };
  }

  updateDimensions = () => {
    // console.log("window has resized");
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight
    });
  }

  componentDidMount(){
    this.props.onSetCategory(LANDING_PAGE_CAT)
    this.props.onFetchPosts(LANDING_PAGE_CAT);
    window.addEventListener("resize", this.updateDimensions);
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.posts !== this.props.posts){
      this.setState({
        isLoading: false,
        id: Math.floor(Math.random() * this.props.posts.length)
      });
    }
  }

  componentWillUnmount(){
    window.removeEventListener("resize", this.updateDimensions);
  }

  onSwipeMove(position, event){
    const swipe = this.state.ySwipe;
    swipe.push(position.y);
    this.setState({
      ySwipe: swipe
    });
  }

  onSwipeStart(event){
    this.setState({
      isSwiping: true
    });
  }

  onSwipeEnd(event){
    if(this.state.isSwiping){

      console.log(this.state.ySwipe[0] - this.state.ySwipe[this.state.ySwipe.length - 1]);
      if(this.state.ySwipe[0] - this.state.ySwipe[this.state.ySwipe.length - 1] > 100){
        this.props.clickHandler();
      }

      if(this.state.ySwipe[0] - this.state.ySwipe[this.state.ySwipe.length - 1] < -100){
        this.props.clickHandler();
      }

      this.setState({
        isSwiping: false,
        ySwipe: []
      });
    }
  }

  getBgStyle = () => {
    const wi = this.props.posts[this.state.id].width;
    const hi = this.props.posts[this.state.id].height;
    const ws = this.state.width;
    const hs = this.state.height;
    const rs = ws / hs;
    const ri = wi / hi;

    if(rs < ri){
      const wnew = wi * (hs/hi);
      const hnew = hs;
      return({
        'position': 'absolute',
        'width': `${wi * (hs/hi)}px`,
        'height': `${hs}px`,
        'top': (hs - hnew) / 2,
        'left': (ws - wnew)
      });
    } else {
      const wnew = ws;
      const hnew = hi * (ws/wi);
      return({
        'position': 'absolute',
        'width': `${ws}px`,
        'height': `${hi * (ws/wi)}px`,
        'top': (hs - hnew) / 2,
        'left': (ws - wnew)
      });
    }
  }

  imgLoadedHandler = () => {
    console.log('image had loaded');

    this.setState({
      alpha: 1.0
    });
  }

  render(){

    const style = {
      WebkitTransform: `translateY(${this.props.offset}%)`,
      MozTransform: `translateY(${this.props.offset}%)`,
      OTransform: `translateY(${this.props.offset}%)`,
      msTransform: `translateY(${this.props.offset}%)`,
      transform: `translateY(${this.props.offset}%)`,
    };

    const bgStyle = (this.state.isLoading) ? null : this.getBgStyle();
    console.log(bgStyle);

    return(
      <Swipe className={styles.swipe}
        onSwipeStart={this.onSwipeStart}
        onSwipeMove={this.onSwipeMove}
        onSwipeEnd={this.onSwipeEnd}>
        <div style={style} className={styles.landingPage} ref={this.props.ref_}>
          <div className={styles.phContainer}>
            <div className={styles.placeholder}>
              <div className={styles.phLoader}>
                <ReactLoading type="spin" color="#000" width={50} height={50} />
              </div>
              <div className={styles.phText}>
                M& DB ARCHITECTEN
              </div>
            </div>
          </div>
          <div className={styles.container} style={{opacity: this.state.alpha}}>
            {
              this.state.isLoading
                ? null
                : <div className={styles.bg}><img style={bgStyle} src={this.props.posts[this.state.id].url} alt="" onLoad={this.imgLoadedHandler}/></div>
            }
            {
              this.state.isLoading
                ? null
                : <div className={styles.wrapper} onClick={this.props.clickHandler}>
                    <div className={styles.textWrapper}>

                      <div className={styles.description}>
                        <div className={styles.descr}>{this.props.posts[this.state.id].title.title}</div>
                        <div className={styles.descrSub}>{this.props.posts[this.state.id].title.sub_title}</div>
                      </div>
                    </div>
                    <EnterButton clickHandler={this.props.clickHandler}/>
                  </div>
            }
          </div>
        </div>
      </Swipe>
    );
  }
}

// <div className={styles.title}>M& DB Architecten</div>

const mapStateToProps = state => ({
  posts: getLandingPage(state),
});

const mapDispatchToProps = (dispatch) => ({
  onFetchPosts: query => dispatch(doFetchLandingPage(query)),
  onSetCategory: category => dispatch(doSetCategory(category)),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(LandingPage));
