import {
  CONTACT_ADD,
  WORKS_ADD,
  PROCESS_ADD,
  NEWS_ADD,
  CV_ADD,
  LANDING_PAGE_ADD,
  SEL_WORKS_ADD,
} from '../constants/actionTypes';

const INITIAL_STATE = {
  contact: [],
  works: [],
  selWorks: [],
  process: [],
  news: [],
  cv: [],
  landingpage: [],
};

const applyAddContact = (state, action) => ({
  contact: action.contact,
  works: state.works,
  selWorks: state.selWorks,
  process: state.process,
  news: state.news,
  cv: state.cv,
  landingpage: state.landingpage,
});

const applyAddWorks = (state, action) => ({
  contact: state.contact,
  works: action.works,
  selWorks: state.selWorks,
  process: state.process,
  news: state.news,
  cv: state.cv,
  landingpage: state.landingpage,
});

const applyAddProcess = (state, action) => ({
  contact: state.contact,
  works: state.works,
  selWorks: state.selWorks,
  process: action.process,
  news: state.news,
  cv: state.cv,
  landingpage: state.landingpage,
});

const applyAddNews = (state, action) => ({
  contact: state.contact,
  works: state.works,
  selWorks: state.selWorks,
  process: state.process,
  news: action.news,
  cv: state.cv,
  landingpage: state.landingpage,
});

const applyAddCv = (state, action) => ({
  contact: state.contact,
  works: state.works,
  selWorks: state.selWorks,
  process: state.process,
  news: state.news,
  cv: action.cv,
  landingpage: state.landingpage,
});

const applyAddLandingPage = (state, action) => ({
  contact: state.contact,
  works: state.works,
  selWorks: state.selWorks,
  process: state.process,
  news: state.news,
  cv: state.cv,
  landingpage: action.landingpage,
});

const applyAddSelWorks = (state, action) => ({
  contact: state.contact,
  works: state.works,
  selWorks: action.selWorks,
  process: state.process,
  news: state.news,
  cv: state.cv,
  landingpage: state.landingpage,
});

function postReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case CONTACT_ADD : {
      return applyAddContact(state, action);
    }
    case WORKS_ADD : {
      return applyAddWorks(state, action);
    }
    case PROCESS_ADD : {
      return applyAddProcess(state, action);
    }
    case NEWS_ADD : {
      return applyAddNews(state, action);
    }
    case CV_ADD : {
      return applyAddCv(state, action);
    }
    case LANDING_PAGE_ADD : {
      return applyAddLandingPage(state, action);
    }
    case SEL_WORKS_ADD : {
      return applyAddSelWorks(state, action);
    }
    default: return state;
  }
}

export default postReducer;
