const getContact = ({ postState }) =>
  postState.contact;

const getWorks = ({ postState }) =>
  postState.works;

const getSelWorks = ({ postState }) =>
  postState.selWorks;

const getProcess = ({ postState }) =>
  postState.process;

const getNews = ({ postState }) =>
  postState.news;

const getCv = ({ postState }) =>
  postState.cv;

const getLandingPage = ({ postState }) =>
  postState.landingpage;

export {
  getContact,
  getWorks,
  getSelWorks,
  getProcess,
  getNews,
  getCv,
  getLandingPage,
}
