import React, { Component } from 'react';

import styles from './MobileMenu.module.css';
import HamburgerMenu from './HamburgerMenu/HamburgerMenu';
import Logo from '../Logo/Logo';

class MobileMenu extends Component {
  state = {
      isActive: false
  };

  hamClickHandler = () => {
    const isActive = !this.state.isActive;
    this.setState({
      isActive: isActive
    });
    this.props.mobMenuClickHandler(isActive);
  }

  menuItemClickHanler = () => {
    if(this.state.isActive) {
      this.setState({
        isActive: false
      });
      this.props.mobMenuClickHandler(false);
    }
  }

  render() {
    return(
      <div className={styles.menu}>

        <div className={styles.header}>
          <div className={styles.title}>
            <Logo language={this.props.language} />
          </div>
          <HamburgerMenu
            clickHandler={this.hamClickHandler}
            itemClickHandler={this.menuItemClickHanler}
            langClickHandler={this.props.langClickHandler}
            isActive={this.state.isActive}
            language={this.props.language} />
        </div>

      </div>
    );
  }
}

export default MobileMenu;
