import {
  LANGUAGE_SET,
} from '../constants/actionTypes';

const doSetLanguage = language => ({
  type: LANGUAGE_SET,
  language,
});

export {
  doSetLanguage,
};
