import React from 'react';
import { withRouter } from 'react-router-dom';

import styles from './ProcessItem.module.css';
// import Title from './Title';
import Content from './Content';

const ProcessItem = (props) => {
  const style = {
    WebkitTransform: `translateY(${props.offset}%)`,
    MozTransform: `translateY(${props.offset}%)`,
    OTransform: `translateY(${props.offset}%)`,
    msTransform: `translateY(${props.offset}%)`,
    transform: `translateY(${props.offset}%)`,
  };

  return(
    <div style={style} className={styles.processItem}>
      <div className={styles.wrapper}>
        <Content post ={props.post} page={props.currPage} />
      </div>
    </div>
  );
}

export default withRouter(ProcessItem);
