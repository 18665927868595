  import React, { Component } from 'react';
import Swipe from 'react-easy-swipe';

import styles from './Navigation.module.css';
import prevBtn from '../../../../../../assets/arrow_left.png';
import nextBtn from '../../../../../../assets/arrow_right.png';
import { SWIPE_SENS } from '../../../../../../constants/swipe';
import Aux from '../../../../../../hoc/Aux';

class Navigation extends Component {
  constructor(props){
    super(props);

    this.onSwipeEnd = this.onSwipeEnd.bind(this);
    this.onSwipeMove = this.onSwipeMove.bind(this);
    this.onSwipeStart = this.onSwipeStart.bind(this);

    this.state = {
      isSwiping: false,
      xSwipe: []
    };
  }

  onSwipeStart(event){
    this.setState({
      isSwiping: true
    });
  }

  onSwipeMove(position, event){
    this.setState(state => {
      const xSwipe = state.xSwipe.concat(position.x);

      return {
        xSwipe
      };
    });
  }

  onSwipeEnd(event){
    if(this.state.isSwiping){
      if(this.state.xSwipe[0] - this.state.xSwipe[this.state.xSwipe.length - 1] > SWIPE_SENS){
        this.props.clickHandler(1, this.props.currPost);
        // console.log("move right");
      }

      if(this.state.xSwipe[0] - this.state.xSwipe[this.state.xSwipe.length - 1] < -SWIPE_SENS){
        this.props.clickHandler(0, this.props.currPost);
        // console.log("move left");
      }

      // console.log(this.state.xSwipe[0] - this.state.xSwipe[this.state.xSwipe.length - 1]);

      this.setState({
        isSwiping: false,
        xSwipe: []
      });
    }

  }

  render(){
    const prevStyle = {
      cursor: 'url(' + prevBtn + '), auto'
    };

    const nextStyle = {
      cursor: 'url(' + nextBtn + '), auto'
    };
    // console.log(this.props.isStart, this.props.isEnd)
    return(
      <Swipe
        className={styles.swipe}
        onSwipeStart={this.onSwipeStart}
        onSwipeMove={this.onSwipeMove}
        onSwipeEnd={this.onSwipeEnd}>
        <div className={styles.Navigation}>
          {
            this.props.isStart && !this.props.isEnd
              ? <div style={nextStyle} className={styles.btnFull} onClick={() => {this.props.clickHandler(1, this.props.currPost)}}/>
              : null
          }
          {
            !this.props.isStart && !this.props.isEnd
              ? <Aux>
                  <div style={prevStyle} className={styles.btn} onClick={() => {this.props.clickHandler(0, this.props.currPost)}}/>
                  <div style={nextStyle} className={styles.btn} onClick={() => {this.props.clickHandler(1, this.props.currPost)}}/>
                </Aux>
              : null
          }
          {
            !this.props.isStart && this.props.isEnd
              ? <div style={prevStyle} className={styles.btnFull} onClick={() => {this.props.clickHandler(0, this.props.currPost)}}/>
              : null
          }

        </div>
      </Swipe>
    );
  }
}

export default Navigation;
