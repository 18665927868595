import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import styles from './Contact.module.css';
import { doFetchContact } from '../../../../actions/post';
import { getContact } from '../../../../selectors/post';
import { doSetCategory } from '../../../../actions/category';
import { CONTACT_CAT } from '../../../../constants/categories';
import ContactItem from './ContactItem/ContactItem';

class Contact extends Component {
  constructor(props){
    super(props);

    this.state = {
      isLoading: true,
      currPost: 0,
    }
  }

  componentDidMount(){
    this.props.onSetCategory(CONTACT_CAT);
    this.props.onFetchContact(CONTACT_CAT);
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.posts !== this.props.posts){
      this.setState({
        isLoading: false
      });
    }
  }

  render(){
    return(
      <div className={styles.contact}>
        <div className={styles.items}>
          {
            (this.props.posts || []).map((post, i) => {
              return <ContactItem
                key={post.id}
                post={post}
              />
            })
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  posts: getContact(state),
});

const mapDispatchToProps = (dispatch) => ({
  onFetchContact: query => dispatch(doFetchContact(query)),
  onSetCategory: category => dispatch(doSetCategory(category)),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Contact));
