import React from 'react';
import { connect } from 'react-redux';

import styles from './FloatingTitle.module.css';
// import arrowImg from '../../../../../assets/titleArrow.png';
import { getLanguage } from '../../../../../selectors/language';

const FloatingTitle = (props) => {
  let title;
  // let place;
  // let year;
  switch(props.language) {
    default:
    case 'nl':
      title = props.currTitleNl;
      // place = (props.meta === null) ? "" : props.meta.meta_nl.place;
      // year = (props.meta === null) ? "" : props.meta.meta_nl.year;
      break;
    case 'en':
      title = props.currTitleEn;
      // place = (props.meta === null) ? "" : props.meta.meta_en.place;
      // year = (props.meta === null) ? "" : props.meta.meta_en.year;
      break;
  }

  // const offset = (window.innerWidth < 768) ? 213 : 600;

  const query = "(-webkit-min-device-pixel-ratio: 1.25),( min--moz-device-pixel-ratio: 1.25), ( -o-min-device-pixel-ratio: 1.25/1), ( min-device-pixel-ratio: 1.25), ( min-resolution: 200dpi), ( min-resolution: 1.25dppx)";
  const orientationQuery = "(min-device-width:768px) and (max-device-width:1024px) and (orientation: portrait)";
  let offset;
  if(window.innerWidth < 768) {
    offset = 300;
  } else if(window.innerWidth >= 768 && window.innerWidth <= 1024 && matchMedia(orientationQuery).matches){
    offset = 400;
    console.log("!!!!!");
  } else if(window.innerWidth > 768 && window.innerWidth <= 1920  && !matchMedia(query).matches) {
    offset = 600;
  } else if(window.innerWidth > 1920 && window.innerWidth <= 2560  && !matchMedia(query).matches) {
    offset = 600;
  } else if(matchMedia(query).matches && window.innerWidth > 768 && window.innerWidth <= 2560 ) {
    offset = 400;
  }

  const style = {
    top: `${((window.innerHeight - offset) / 2) + Math.abs(offset)}px`
  };

  // console.log(style);

  return(
    <div style={style} className={styles.FloatingTitle}>
      <div className={styles.titleWrapper}>
        <div className={styles.title}>
          {
            props.showTitle
              ? title
              : null
          }
        </div>
      </div>

    </div>
  );
}

const mapStateToProps = state => ({
  language: getLanguage(state),
});

export default connect(
  mapStateToProps
)(FloatingTitle)
