import React from 'react';
import { withRouter } from 'react-router-dom';

import styles from './ProjectItem.module.css';
import Content from './Content';
import prevImg from '../../../../../assets/arrow_up.png';
import nextImg from '../../../../../assets/arrow_down.png';

const ProjectItem = (props) => {
  const prevId = props.currPost - 1;
  const nextId = props.currPost + 1;

  let content;
  const contentElement = <Content
                            post ={props.post}
                            page={props.currPage}
                            hasNav={props.pid === props.currPost ? true : false}
                            currPost={props.currPost}
                            pageClickHandler={props.pageClickHandler}
                            totPosts={props.totPosts}
                         />;
  // console.log(props.totPosts, props.currPage)
  if(prevId === props.pid){
    const prevStyle = {
      cursor: 'url(' + prevImg + '), auto'
    };
    const opacityStyle = {
      opacity: '1'
    }
    content = <div style={prevStyle} className={styles.prevBtn} onClick={() => {props.postClickHandler(0)}}>
                <div style={opacityStyle} className={styles.opacity}>{contentElement}</div>
                <div className={styles.prevImg}>vorige</div>
              </div>;
  } else if(nextId === props.pid){
    const nextStyle = {
      cursor: 'url(' + nextImg + '), auto'
    };

    const query = "(-webkit-min-device-pixel-ratio: 1.25) and (max-device-width: 2560px), ( min--moz-device-pixel-ratio: 1.25) and (max-device-width: 2560px), ( -o-min-device-pixel-ratio: 1.25/1) and (max-device-width: 2560px), ( min-device-pixel-ratio: 1.25) and (max-device-width: 2560px), ( min-resolution: 200dpi) and (max-device-width: 2560px), ( min-resolution: 1.25dppx) and (max-device-width: 2560px)";
    const orientationQuery = "(min-device-width:768px) and (max-device-width:1024px) and (orientation: portrait)";
    // const mobileQuery = "(-webkit-min-device-pixel-ratio: 1.25) and (max-device-width: 768px), ( min--moz-device-pixel-ratio: 1.25) and (max-device-width: 768px), ( -o-min-device-pixel-ratio: 1.25/1) and (max-device-width: 768px), ( min-device-pixel-ratio: 1.25) and (max-device-width: 768px), ( min-resolution: 200dpi) and (max-device-width: 768px), ( min-resolution: 1.25dppx) and (max-device-width: 768px)";
    let marginTop;
    if(window.innerWidth < 768) {
      marginTop = (window.innerHeight - (283 + 70)) / 4;
    } else if(window.innerWidth >= 768 && window.innerWidth <= 1024 && matchMedia(orientationQuery).matches){
      marginTop = (window.innerHeight - (416 + 150)) / 4;
      // console.log("2");
    } else if(window.innerWidth > 768 && window.innerWidth <= 1920 && !matchMedia(query).matches) {
      marginTop = (window.innerHeight - (670 + 70)) / 4;
    } else if(window.innerWidth > 1920 && window.innerWidth <= 2560 && !matchMedia(query).matches) {
      marginTop = (window.innerHeight - (850 + 250)) / 4;
    } else if(matchMedia(query).matches && window.innerWidth > 768 && window.innerWidth <= 2560) {
      marginTop = (window.innerHeight - (536 + 70)) / 4;
    }

    const marginStyle = {
      marginTop: `${marginTop}px`
    };

    const opacityStyle = {
      opacity: '1'
    }
    content = <div style={nextStyle} className={styles.nextBtn} onClick={() => {props.postClickHandler(1)}}>
                <div style={opacityStyle} className={styles.opacity}>{contentElement}</div>
                <div style={marginStyle} className={styles.nextImg}><img src={nextImg} alt="" /></div>
              </div>;
  } else {
    const opacityStyle = {
      opacity: '1'
    }
    content = <div style={opacityStyle} className={styles.opacity}>{contentElement}</div>
  }

  let style;
  if(props.pid === props.currPost){
    style = {
      WebkitTransform: `translateY(${props.offset}px)`,
      MozTransform: `translateY(${props.offset}px)`,
      OTransform: `translateY(${props.offset}px)`,
      msTransform: `translateY(${props.offset}px)`,
      transform: `translateY(${props.offset}px)`,
      WebkitFilter: 'grayscale(0%)',
      MozFilter: 'grayscale(0%)',
      OFilter: 'grayscale(0%)',
      msFilter: 'grayscale(0%)',
      filter: 'grayscale(0%)',
      opacity: '1'
    };
  } else {
    style = {
      WebkitTransform: `translateY(${props.offset}px)`,
      MozTransform: `translateY(${props.offset}px)`,
      OTransform: `translateY(${props.offset}px)`,
      msTransform: `translateY(${props.offset}px)`,
      transform: `translateY(${props.offset}%px)`,
      WebkitFilter: 'grayscale(100%)',
      MozFilter: 'grayscale(100%)',
      OFilter: 'grayscale(100%)',
      msFilter: 'grayscale(100%)',
      filter: 'grayscale(100%)',
      opacity: '0.35'
    };
  }

  return(
    <div className={styles.projectItem}>
      <div style={style} className={styles.wrapper}>
        {content}
      </div>
    </div>
  );
}

export default withRouter(ProjectItem);
