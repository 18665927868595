import React from 'react';

import styles from './MenuItems.module.css';
import { MENU_ITEMS } from '../../../../constants/menu';
import MenuItem from './MenuItem/MenuItem';

const MenuItems = (props) => {
  const menuItems = MENU_ITEMS.map((item, i) => {
    return <MenuItem
              key={i}
              item={item}
              language={props.language}
              active={props.category === item.id.toLowerCase()}
              clickHandler={props.itemClickHandler} />
  });

  const divider1 = <div key={menuItems.length} className={[styles.divider, styles.purple].join(' ')}>
    <div className={styles.left} />
    <div className={styles.right} />
  </div>;

  const divider2 = <div key={menuItems.length+1} className={[styles.divider, styles.blue].join(' ')}>
    <div className={styles.left} />
    <div className={styles.right} />
  </div>;

  // menuItems.splice(2, 0, divider1);
  // menuItems.splice(6, 0, divider2);
  // menuItems.splice(0, 0, divider1);
  menuItems.splice(5, 0, divider2);

  return(
    <div className={styles.MenuItems}>
      {menuItems}
    </div>
  );
}

export default MenuItems;
