import React from 'react';

import styles from './HamburgerIcon.module.css';
import closeImg from '../../../../../../assets/cross.png';
import hamburgerImg from '../../../../../../assets/hamburger.png';

const HamburgerIcon = (props) => {
  return(
    <div className={styles.HamburgerIcon} onClick={props.clickHandler}>
      <div className={styles.image}>
        {
          props.isActive
            ? <img src={closeImg} alt="hide menu" />
            : <img src={hamburgerImg} alt="show menu" />
        }
      </div>
    </div>
  );
}

export default HamburgerIcon;
