import React from 'react';

import styles from './Navigation.module.css';
import nextImg from '../../../../../assets/arrow_down.png';

const Navigation = (props) => {
  return(
    <div className={styles.Navigation}>
      <div className={styles.nextBtn} onClick={() => props.clickHandler(1)}>
        <img src={nextImg} alt="" />
      </div>
    </div>
  );
}

export default Navigation;
