import React from 'react';

import styles from './ContactItem.module.css';

const ContactDetails = (props) => {
  return(
    <div className={styles.contactDetails}>
      <div className={styles.addressHeader}>
        {
          props.language === 'nl'
            ? "Contact: "
            : "Contact: "
        }
      </div>
      {
        Object.keys(props.contact).map((key, i) => {
          return <div key={i} className={styles.contactItem}>
                    {props.contact[key]}
                 </div>
        })
      }
    </div>
  );
}

export default ContactDetails;
