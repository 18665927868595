import {
  CATEGORY_SET,
} from '../constants/actionTypes';

const doSetCategory = category => ({
  type: CATEGORY_SET,
  category,
});

export {
  doSetCategory,
};
