// export const POSTS_FETCH = 'POSTS_FETCH';
export const CONTACT_FETCH = 'CONTACT_FETCH';
export const WORKS_FETCH = 'WORKS_FETCH';
export const PROCESS_FETCH = 'PROCESS_FETCH';
export const NEWS_FETCH = 'NEWS_FETCH';
export const CV_FETCH = 'CV_FETCH';
export const LANDING_PAGE_FETCH = 'LANDING_PAGE_FETCH';
// export const POSTS_FETCH_ERROR = 'POSTS_FETCH_ERROR';
// export const POSTS_ADD = 'POSTS_ADD';
export const CONTACT_ADD = 'CONTACT_ADD';
export const WORKS_ADD = 'WORKS_ADD';
export const PROCESS_ADD = 'PROCESS_ADD';
export const NEWS_ADD = 'NEWS_ADD';
export const CV_ADD = 'CV_ADD';
export const LANDING_PAGE_ADD = 'LANDING_PAGE_ADD';
export const LANGUAGE_SET = 'LANGUAGE_SET';
export const CATEGORY_SET = 'CATEGORY_SET';
export const SEL_WORKS_ADD = 'SEL_WORKS_ADD';
export const SEL_WORKS_FETCH = 'SEL_WORKS_FETCH';
// export const IS_FETCHING_SET = 'IS_FETCHING_SET';
