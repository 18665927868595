import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from './Menu.module.css';
import MenuItems from './MenuItems/MenuItems';
import Language from './Language/Language';
// import Title from './Title/Title';
import MobileMenu from './MobileMenu/MobileMenu';
import SocialMenu from './SocialMenu/SocialMenu';
import Logo from './Logo/Logo';
import { getLanguage } from '../../../selectors/language';
import { getCategory } from '../../../selectors/category';

class Menu extends Component {
  render() {
    const classes = this.props.shouldAnim
      ? [styles.menu, styles.menuAnim].join(' ')
      : styles.menu;

    const style = this.props.shouldAnim
      ? {
          WebkitTransform: `translateY(${this.props.offset}%)`,
          MozTransform: `translateY(${this.props.offset}%)`,
          OTransform: `translateY(${this.props.offset}%)`,
          msTransform: `translateY(${this.props.offset}%)`,
          transform: `translateY(${this.props.offset}%)`,
        }
      : null;
    return(
      <div style={style} className={classes}>
        {this.props.children}
        <div className={styles.mobileMenu}>
          <MobileMenu
            langClickHandler={this.props.langClickHandler}
            language={this.props.language}
            mobMenuClickHandler={this.props.mobMenuClickHandler} />
        </div>
        <div className={styles.menuWrapper}>
          <div className={styles.logo}>
            <Logo language={this.props.language} />
          </div>
          <div className={styles.menuItems}>
            <MenuItems language={this.props.language} category={this.props.category} />
          </div>
        </div>
        <div className={styles.lang}>
          <Language clickHandler={this.props.langClickHandler} />
        </div>
        <div className={styles.social}>
          <SocialMenu />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: getLanguage(state),
  category: getCategory(state),
});

export default connect(
  mapStateToProps
)(Menu);
