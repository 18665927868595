import React from 'react';
import { withRouter } from 'react-router-dom';

import styles from './NewsItem.module.css';
import Content from './Content';

const NewsItem = (props) => {
  // console.log(props);
  return(
    <div className={styles.newsItem}>
      <div className={styles.wrapper}>
        <Content post ={props.post} page={props.currPage} />
      </div>
    </div>
  );
}

export default withRouter(NewsItem);
