import React from 'react';
import { connect } from 'react-redux';

import styles from './ProjectItem.module.css';
import { getLanguage } from '../../../../../selectors/language';
import Navigation from './Navigation/Navigation';
import Text from './Text';
import Aux from '../../../../../hoc/Aux';

const Content = (props) => {
  const imgsOrdered = props.post.images.sort(function (a, b) {
      return a.order - b.order;
  });

  const styleImg = {
    marginRight: `${window.innerWidth / 2}px`
  };

  const content = imgsOrdered.map((img, i) => {
    return <div key={i} style={styleImg} className={styles.image} >
              <img src={img.guid} alt=""/>

           </div>
  });

  // console.log(content.length, props.page)

  const text =  <div key={content.length} style={styleImg} className={styles.textContainer}>
                  <Text
                    post={props.post}
                    page={props.page}
                    language={props.language} />
                  {
                    props.hasNav
                      ? <Navigation currPost={props.currPost} clickHandler={props.pageClickHandler}/>
                      : null
                  }
                </div>;

  content.splice(1, 0, text);
  const query = "(-webkit-min-device-pixel-ratio: 1.25) and (max-device-width: 2560px), ( min--moz-device-pixel-ratio: 1.25) and (max-device-width: 2560px), ( -o-min-device-pixel-ratio: 1.25/1) and (max-device-width: 2560px), ( min-device-pixel-ratio: 1.25) and (max-device-width: 2560px), ( min-resolution: 200dpi) and (max-device-width: 2560px), ( min-resolution: 1.25dppx) and (max-device-width: 2560px)";
  const orientationQuery = "(min-device-width:768px) and (max-device-width:1024px) and (orientation: portrait)";
  // const imgSize = (window.innerWidth < 768) ? 320 : 900;
  let imgSize;
  let offset;
  if(window.innerWidth < 768) {
    // marginTop = (window.innerHeight - (283 + 70)) / 4;
    imgSize = 320;
    offset = (window.innerWidth / 2) + imgSize;
    // console.log(offset);
  } else if(window.innerWidth >= 768 && window.innerWidth <= 1024 && matchMedia(orientationQuery).matches){
    imgSize = 400;
    offset = (window.innerWidth / 2) + imgSize;
    // console.log(offset);
  } else if(window.innerWidth > 768 && window.innerWidth <= 1920 && !matchMedia(query).matches) {
    // marginTop = (window.innerHeight - (670 + 70)) / 4;
    imgSize = 900;
    offset = (window.innerWidth / 2) + imgSize;
  } else if(window.innerWidth > 1920 && window.innerWidth <= 2560 && !matchMedia(query).matches) {
    // marginTop = (window.innerHeight - (850 + 250)) / 4;
    imgSize = 900;
    offset = (window.innerWidth / 2) + imgSize;
  } else if(matchMedia(query).matches && window.innerWidth > 768 && window.innerWidth <= 2560) {
    imgSize = 700;
    offset = (window.innerWidth / 2) + imgSize;
  }

  // console.log(offset);
  // else if(window.innerWidth > 768 &&  matchMedia(tabletQuery)){
  //   imgSize = 400;
  //   offset = (window.innerWidth / 2) + imgSize;
  // }

  const style = {
    WebkitTransform: `translateX(${props.page * -offset}px)`,
    MozTransform: `translateX(${props.page * -offset}px)`,
    OTransform: `translateX(${props.page * -offset}px)`,
    msTransform: `translateX(${props.page * -offset}px)`,
    transform: `translateX(${props.page * -offset}px)`
  }
  // console.log(props.currPost, props.totPosts)
  return(
    <Aux>
      <div style={style} className={styles.content}>
        <div className={styles.container}>
          {content}
        </div>
      </div>
      {
        props.hasNav
          ? <Navigation
              currPost={props.currPost}
              clickHandler={props.pageClickHandler}
              isStart={props.page === 0 && props.currPost === 0}
              isEnd={props.page === content.length - 1 && props.currPost === props.totPosts - 1}/>
          : null
      }
    </Aux>
  );
}

const mapStateToProps = state => ({
  language: getLanguage(state),
});

export default connect(
  mapStateToProps
)(Content);
