import React from 'react';
import { withRouter } from 'react-router-dom';

import styles from './CvItem.module.css';
import Content from './Content';

const CvItem = (props) => {
  const translate = 'translateY(' + -props.offset + '%)';
  const style = {
    transform: translate
  }

  return(
    <div style={style} className={styles.cvItem} ref={props.ref_}>
      <div className={styles.wrapper}>
        <Content post ={props.post} page={props.currPage} />
      </div>
    </div>
  );
}

export default withRouter(CvItem);
