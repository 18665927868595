import React from 'react';

import styles from './ContactItem.module.css';

const Address = (props) => {
  return(
    <div className={styles.address}>
      <div className={styles.addressHeader}>
        {
          props.language === 'nl'
            ? "Adres: "
            : "Address: "
        }
      </div>
      {
        Object.keys(props.address).map((key, i) => {
          return <div key={i} className={styles.addressItem}>
                    {props.address[key]}
                 </div>
        })
      }
    </div>
  );
}

export default Address;
