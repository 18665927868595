import React from 'react';

import styles from './ProjectItem.module.css';

const Text = (props) => {
  let text;
  // const styleText = props.page === 1
  //   ? [styles.textWrapper, styles.onTop].join(' ')
  //   : [styles.textWrapper, styles.onBottom].join(' ')

  switch(props.language) {
    default:
    case 'nl':
      text = <div key={props.post.id} className={styles.textWrapper}>
              <div className={styles.textTitle}>
                {props.post.title_nl}
              </div>
              {
                props.post.meta
                  ? <div className={styles.textMeta}>
                     <table>
                       <tbody>
                         <tr>
                           <td className={styles.textMetaHeader}>Status:</td>
                           <td className={styles.textPurple}>{props.post.meta.meta_nl.status}</td>
                         </tr>
                         <tr>
                           <td className={styles.textMetaHeader}>Locatie:</td>
                           <td className={styles.textPurple}>{props.post.meta.meta_nl.location}</td>
                         </tr>
                         <tr>
                           <td className={styles.textMetaHeader}>Programma:</td>
                           <td className={styles.textPurple}>{props.post.meta.meta_nl.program}</td>
                         </tr>
                         <tr>
                           <td className={styles.textMetaHeader}>Opdrachtgever:</td>
                           <td className={styles.textPurple}>{props.post.meta.meta_nl.client}</td>
                         </tr>
                       </tbody>
                     </table>
                    </div>
                  : null
              }
               <div
                 className={styles.text}
                 dangerouslySetInnerHTML={{__html: props.post.textNl}}
               />
             </div>
      break;
    case 'en':
      text = <div key={props.post.id} className={styles.textWrapper}>
              <div className={styles.textTitle}>
                {props.post.title_en}
              </div>
              {
                props.post.meta
                  ? <div className={styles.textMeta}>
                     <table>
                       <tbody>
                         <tr>
                           <td className={styles.textMetaHeader}>Status:</td>
                           <td className={styles.textPurple}>{props.post.meta.meta_en.status}</td>
                         </tr>
                         <tr>
                           <td className={styles.textMetaHeader}>Location:</td>
                           <td className={styles.textPurple}>{props.post.meta.meta_en.location}</td>
                         </tr>
                         <tr>
                           <td className={styles.textMetaHeader}>Program:</td>
                           <td className={styles.textPurple}>{props.post.meta.meta_en.program}</td>
                         </tr>
                         <tr>
                           <td className={styles.textMetaHeader}>Client:</td>
                           <td className={styles.textPurple}>{props.post.meta.meta_en.client}</td>
                         </tr>
                       </tbody>
                     </table>
                    </div>
                  : null
              }
               <div
                 className={styles.text}
                 dangerouslySetInnerHTML={{__html: props.post.textEn}}
               />
             </div>
      break;
  }

  return(
    text
  );
}

export default Text;
