import React from 'react';
import { connect } from 'react-redux';

import styles from './Language.module.css';
import { getLanguage } from '../../../../selectors/language';

// <button
//   className={
//     props.language === 'nl'
//     ? [styles.nl, styles.active].join(' ')
//     : [styles.nl, styles.notActive].join(' ')
//   }
//   onClick={() => {props.clickHandler('nl')}}
// >NL</button> - <button
//                 className={
//                   props.language === 'en'
//                   ? [styles.en, styles.active].join(' ')
//                   : [styles.en, styles.notActive].join(' ')
//                 }
//                 onClick={() => {props.clickHandler('en')}}
//                >EN</button>


const Language = (props) => {
  let btn;
  switch(props.language){
    default:
    case 'nl':
      btn = <a onClick={() => {props.clickHandler('en')}}>EN</a>;
      break;
    case 'en' :
      btn = <a onClick={() => {props.clickHandler('nl')}}>NL</a>;
  }

  return(
    <div className={styles.language}>
      {btn}
    </div>
  );
}

const mapStateToProps = state => ({
  language: getLanguage(state),
});

export default connect(
  mapStateToProps
)(Language);
