import React from 'react';

import styles from './SocialMenu.module.css';
import facebookImg from '../../../../assets/social/facebook.png';
import instagramImg from '../../../../assets/social/instagram.png';
import pinterestImg from '../../../../assets/social/pinterest.png';

const SocialMenu = (props) => {
  return (
    <div className={styles.socialmenu}>
      <div className={styles.wrapper}>
        <a
          href="https://www.facebook.com/MenDBarchitecten"
          target="_blank"
          rel="noopener norefferer" >
            <img src={facebookImg} alt="facebook"/>
        </a>
        <a
          href="https://www.instagram.com/m_db_architecten"
          target="_blank"
          rel="noopener norefferer" >
          <img src={instagramImg} alt="instagram" />
        </a>
        <a
          href="https://nl.pinterest.com/m_db_architecten/"
          target="_blank"
          rel="noopener norefferer">
          <img src={pinterestImg} alt="pinterest" />
        </a>
      </div>
    </div>
  );
}

export default SocialMenu;
