import React from 'react';
import { connect } from 'react-redux';

import styles from './ContactItem.module.css';
import { getLanguage } from '../../../../../selectors/language';
import Address from './Address';
import ContactDetails from './ContactDetails';

const Content = (props) => {
  const data = props.language === 'nl' ? props.post.nl : props.post.en;
  return(
    <div className={styles.content}>
      <div className={styles.map} dangerouslySetInnerHTML={{__html: props.post.loc_embed}}/>
      <div className={styles.text}>
        <Address address={data.address} language={props.language}/>
        <ContactDetails contact={data.contact} language={props.language}/>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  language: getLanguage(state),
});

export default connect(
  mapStateToProps
)(Content);
