import React from 'react';
import { connect } from 'react-redux';

import styles from './NewsItem.module.css';
import Aux from '../../../../../hoc/Aux';
import { getLanguage } from '../../../../../selectors/language';

const Title = (props) => {
  const date = new Date(props.date);
  const options = {year: 'numeric', month: 'long', day: 'numeric' };

  return(
    <Aux>
      <div className={styles.date}>
        {
            props.language === 'nl'
            ? date.toLocaleDateString('nl-NL', options)
            : date.toLocaleDateString('en-EN', options)
        }
      </div>
      <div className={styles.title}>
        {props.title}
      </div>
    </Aux>
  );
}

const mapStateToProps = state => ({
  language: getLanguage(state),
});

export default connect(
  mapStateToProps
)(Title);
