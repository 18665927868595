import React from 'react';
import { connect } from 'react-redux';

import styles from './CvItem.module.css';
import { getLanguage } from '../../../../../selectors/language';

const Content = (props) => {
  let text;
  switch(props.language) {
    default:
    case 'nl':
      text = <div className={styles.textWraper}>
              <div
                key={props.post.id}
                className={styles.text}
                dangerouslySetInnerHTML={{__html: props.post.textNl}}
              />
              <div
                key={props.post.id + 1}
                className={styles.mobileText}
                dangerouslySetInnerHTML={{__html: props.post.mobile_text.text_nl}}
              />
            </div>
      break;
    case 'en':
      text = <div className={styles.textWraper}>
              <div
                key={props.post.id}
                className={styles.text}
                dangerouslySetInnerHTML={{__html: props.post.textEn}}
              />
              <div
                key={props.post.id + 1}
                className={styles.mobileText}
                dangerouslySetInnerHTML={{__html: props.post.mobile_text.text_en}}
              />
            </div>
      break;
  }

  const images = Object.keys(props.post.images).map((key, i) => {
    const style = props.post.images[key].width > props.post.images[key].height
      ? [styles.image, styles.landscape].join(' ')
      : [styles.image, styles.portrait].join(' ');
    return <div key={key} className={style} >
              <img src={props.post.images[key].guid} alt=""/>
           </div>
  });

  return(
    <div className={styles.content}>
      <div className={styles.images}>
        {images}
      </div>
      {text}
    </div>
  );
}

const mapStateToProps = state => ({
  language: getLanguage(state),
});

export default connect(
  mapStateToProps
)(Content);
