import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import styles from './Cv.module.css';
import { CV_CAT } from '../../../../constants/categories';
import { doFetchCv } from '../../../../actions/post';
import { getCv } from '../../../../selectors/post';
import { doSetCategory } from '../../../../actions/category';
import CvItem from './CvItem/CvItem';
import Aux from '../../../../hoc/Aux';

class Cv extends Component {
  constructor(props){
    super(props);

    this.state = {
      isLoading: true,
      currPost: 0,
      scrollPos: 0,
    }

    this.itemRefs = [];
  }

  componentDidMount(){
    this.props.onSetCategory(CV_CAT);
    this.props.onFetchPosts('cv');
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.posts !== this.props.posts){
      this.setState({
        isLoading: false
      });

      this.itemRefs = this.props.posts.map(() => {
        return React.createRef()
      });
    }

    if(prevState.isLoading === true && this.state.isLoading === false){
      if(prevProps.match.isExact === false){
        const locArr = prevProps.location.pathname.split('/');
        const title = locArr[3];
        let pid = 0;
        this.props.posts.forEach((post, i) => {
          if(title === post.title.toLowerCase()){
            pid = i;
          }
        });
        this.itemRefs[pid].current.scrollIntoView();
        this.setState({
          currPost: pid,
        });
      }
    }
  }

  postClickHandler = (dir) => {
    let scrollPos = this.state.scrollPos;
    if (dir === 0) {
      scrollPos -= 10;
      scrollPos = scrollPos < 0 ? 0 : scrollPos;
    } else if (dir === 1 && this.itemRefs[0].current.getBoundingClientRect().bottom > window.innerHeight) {
      scrollPos += 10;
      scrollPos = scrollPos > 100 ? 100 : scrollPos;
    }
    this.setState({
      scrollPos: scrollPos,
    });
  }

  wheelHandler = (e) => {
    if(e.deltaY < 0) {
      this.postClickHandler(0);
    } else {
      this.postClickHandler(1);
    }
  }

  render(){
    return(
      <Aux>
        <div className={styles.cv}>
          <div className={styles.items}>
            {
              (this.props.posts || []).map((post, i) => {
                return <CvItem
                  key={post.id}
                  post={post}
                  offset={this.state.scrollPos}
                  ref_={this.itemRefs[i]}
                />
              })
            }
          </div>
        </div>
      </Aux>
    );
  }
}

const mapStateToProps = state => ({
  posts: getCv(state),
});

const mapDispatchToProps = (dispatch) => ({
  onFetchPosts: query => dispatch(doFetchCv(query)),
  onSetCategory: category => dispatch(doSetCategory(category)),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Cv));
