import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import styles from './Item.module.css';
import Image from '../Image/Image';
import Text from '../Text/Text';
import { SEL_WORK_CAT } from '../../../../../constants/categories';

class Item extends Component {
  state = {
    isHover: false
  };

  onHoverHandler(isEntered) {
    if(this.state.isHover !== isEntered){
      this.setState({
        isHover: isEntered
      });
    }
  }

  render(){
    // const textStyle = (this.state.isHover)
    //   ? {
    //     opacity: '1'
    //   }
    //   : {
    //     opacity: '0'
    //   }

    // const imgStyle = (this.state.isHover)
    //   ? {
    //     WebkitFilter: 'grayscale(100%) blur(1px)',
    //     MozFilter: 'grayscale(100%) blur(1px)',
    //     OFilter: 'grayscale(100%) blur(1px)',
    //     msFilter: 'grayscale(100%) blur(1px)',
    //     filter: 'grayscale(100%) blur(1px)',
    //   }
    //   : {
    //     WebkitFilter: 'grayscale(0%) blur(0)',
    //     MozFilter: 'grayscale(0%) blur(0)',
    //     OFilter: 'grayscale(0%) blur(0)',
    //     msFilter: 'grayscale(0%) blur(0)',
    //     filter: 'grayscale(0%) blur(0)',
    //   }

    // let text;
    // switch(this.props.language){
    //   default:
    //   case 'nl':
    //     text = <div className={styles.text} dangerouslySetInnerHTML={{__html: this.props.post.description.description_nl}} />
    //     break;
    //   case 'en':
    //     text = <div className={styles.text} dangerouslySetInnerHTML={{__html: this.props.post.description.description_en}} />
    //     break;
    // }

    // console.log(this.props.post.text);

    // <div className={styles.Item} onMouseEnter={() => this.onHoverHandler(true)} onMouseLeave={() => this.onHoverHandler(false)}>

    return(
      <div className={styles.Item} onMouseEnter={() => this.onHoverHandler(true)} onMouseLeave={() => this.onHoverHandler(false)}>
          {
            this.props.post.link === null ?
              <div className={styles.wrapper}>
                <div className={styles.image}>
                  <Image path={this.props.post.url} greyscale={true}/>
                </div>
                <div className={styles.textWrapper}>
                  <Text text={this.props.post.text} language={this.props.language} hasLink={false} isHover={this.state.isHover} />
                </div>
              </div> :
              <div className={styles.wrapper}>
                <Link
                  to={'/' + this.props.language + '/' + SEL_WORK_CAT + '/' + this.props.post.link.toLowerCase() + '/0'}
                  exact="true">
                  <div className={styles.image}>
                    <Image path={this.props.post.url} greyscale={false}/>
                  </div>
                  <div className={styles.textWrapper}>
                    <Text text={this.props.post.text} language={this.props.language} hasLink={true} isHover={this.state.isHover} />
                  </div>
                </Link>
              </div>
          }
      </div>
    );
  }
}

export default Item;
