import React from 'react';
import { Link } from 'react-router-dom';

import styles from './MenuItem.module.css';

const MenuItem = (props) => {
  let style;
  if(props.active){
    style = [styles.menuItem, styles.active].join(' ');
  } else {
    style = styles.menuItem;
  }

  const itemName = props.language === 'nl' ? props.item.nameNL : props.item.nameEN;

  const colorStyle = {
    color: props.item.color
  }
  
  return(
    <div className={style}>
    <Link
      style={colorStyle}
      to={'/' + props.language + '/' + props.item.id.toLowerCase()}
      exact="true"
      onClick={props.clickHandler === undefined ? null : props.clickHandler}
    >{itemName}</Link>
    </div>
  );
}

export default MenuItem;
