export const MENU_ITEMS = [
  {
    nameNL: 'Selectie',
    nameEN: 'Selected',
    id: 'selected_work',
    color: 'rgb(0, 0, 0)'
  },
  {
    nameNL: 'Overzicht',
    nameEN: 'Overview',
    id: 'overview_work',
    color: 'rgb(0, 0, 0)'
  },
  {
    nameNL: 'Archief',
    nameEN: 'Archive',
    id: 'archive',
    color: 'rgb(0, 0, 0)'
  },
  {
    nameNL: 'Nieuws',
    nameEN: 'News',
    id: 'news',
    color: 'rgb(0, 0, 0)'
  },
  {
    nameNL: 'Over ons',
    nameEN: 'About',
    id: 'about',
    color: 'rgb(0, 0, 0)'
  },
  {
    nameNL: 'Contact',
    nameEN: 'Contact',
    id: 'contact',
    color: 'rgb(0, 0, 0)'
  },
];
