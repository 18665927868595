import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Swipe from 'react-easy-swipe';

import styles from './Projects.module.css';
import { SEL_WORK_CAT } from '../../../../constants/categories';
import { doFetchSelWorks } from '../../../../actions/post';
import { getSelWorks } from '../../../../selectors/post';
import { doSetCategory } from '../../../../actions/category';
import ProjectItem from './ProjectItem/ProjectItem';
import FloatingTitle from './FloatingTitle/FloatingTitle';
import { SWIPE_SENS } from '../../../../constants/swipe';
import { SCROLL_WHEEL_THRESHOLD } from '../../../../constants/scroll';

class Projects extends Component {
  constructor(props){
    super(props);

    this.onSwipeEnd = this.onSwipeEnd.bind(this);
    this.onSwipeMove = this.onSwipeMove.bind(this);
    this.onSwipeStart = this.onSwipeStart.bind(this);

    this.wheelDelta = {
        prev: 0,
        tot: 0
    };

    this.state = {
      isLoading: true,
      currPost: 0,
      pages: [],
      isSwiping: false,
      ySwipe: [],
      width: window.innerWidth,
      height: window.innerHeight,
    }
  }

  componentDidMount(){
    this.props.onSetCategory(SEL_WORK_CAT);
    this.props.onFetchPosts(SEL_WORK_CAT);
    window.addEventListener("orientationchange", this.handleOrientation);
    window.addEventListener("resize", this.handleOrientation);
  }

  componentWillUnmount(){
    window.removeEventListener("orientationchange", this.handleOrientation);
    window.removeEventListener("resize", this.handleOrientation);
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.match.isExact){
        if(this.props.match.isExact){
          this.props.history.push({pathname: this.props.match.url + '/' + this.props.posts[0].title_nl.toLowerCase() + '/0'});

          const pages = this.props.posts.map(() => {
            return 0;
          });

          this.setState({
            currPost: 0,
            pages: pages
          });
        }
    }

    if(prevProps.posts !== this.props.posts){
      const pages = this.props.posts.map(() => {
        return 0;
      });

      this.setState({
        isLoading: false,
        pages: pages,
      });
    }

    if(prevState.isLoading === true && this.state.isLoading === false){
      if(prevProps.match.isExact === false){
        const locArr = prevProps.location.pathname.split('/');
        const title = locArr[3];
        let pid = 0;
        this.props.posts.forEach((post, i) => {
          if(title === post.title_nl.toLowerCase()){
            pid = i;
          }
        });

        let pages = this.state.pages;
        pages[pid] = parseInt(locArr[4]);
        this.setState({
          currPost: pid,
          pages: pages
        });
      }
    }
  }

  handleOrientation = (event) => {
    console.log(event);
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight
    });
  }

  onSwipeStart(event){
    this.setState({
      isSwiping: true
    });
  }

  onSwipeMove(position, event){
    this.setState(state => {
      const ySwipe = state.ySwipe.concat(position.y);

      return {
        ySwipe
      };
    });
  }

  onSwipeEnd(event){
    if(this.state.isSwiping){
      if(this.state.ySwipe[0] - this.state.ySwipe[this.state.ySwipe.length - 1] > SWIPE_SENS){
        this.postClickHandler(1);
      }

      if(this.state.ySwipe[0] - this.state.ySwipe[this.state.ySwipe.length - 1] < -SWIPE_SENS){
        this.postClickHandler(0);
      }

      this.setState({
        isSwiping: false,
        ySwipe: []
      });
    }

  }

  postClickHandler = (dir, startPage = 0) => {
      const size = this.props.posts.length;
      let currPost = this.state.currPost;
      let pages = this.state.pages;

      pages[currPost] = 0;

      if (dir === 0) {
        currPost -= 1;
        currPost = currPost < 0 ? 0 : currPost;
      } else if (dir === 1) {
        currPost += 1;
        currPost = currPost > size - 1 ? size - 1 : currPost;
      }

      pages[currPost] = startPage;

      this.props.history.push({pathname: this.props.match.url + '/' + this.props.posts[currPost].title_nl.toLowerCase() + '/' + pages[currPost]});

      this.setState({
        currPost: currPost,
        pages: pages,
      });
  }

  pageClickHandler = (dir, currPost) => {
      const size = Object.keys(this.props.posts[currPost].images).length
      const postsAmnt = this.props.posts.length;
      let pages = this.state.pages;

      if(pages.length === 0){
        pages = this.props.posts.map(() => {
          return 0;
        });
      }

      if(dir === 0) {
        pages[currPost] -= 1;
        if(pages[currPost] < 0) {
          pages[currPost] = 0;
          if(currPost - 1 >= 0) {
            const newSize = Object.keys(this.props.posts[currPost - 1].images).length;
            this.postClickHandler(0, newSize);
          }
        } else {
          pages[currPost] = pages[currPost] < 0 ? size : pages[currPost];
          this.props.history.push({pathname: this.props.match.url + '/' + this.props.posts[currPost].title_nl.toLowerCase() + '/' + pages[currPost]});
          this.setState({
            pages: pages
          });
        }
      } else if(dir === 1) {
        pages[currPost] += 1;
        if(pages[currPost] > size) {
          pages[currPost] = size;
          if(currPost + 1 < postsAmnt){
            this.postClickHandler(1, 0);
          }
          // else {
          //   this.postClickHandler(1, 0);
          // }
        } else {
          pages[currPost] = pages[currPost] > size ? size : pages[currPost];
          this.props.history.push({pathname: this.props.match.url + '/' + this.props.posts[currPost].title_nl.toLowerCase() + '/' + pages[currPost]});
          this.setState({
            pages: pages
          });
        }
      }
  }

  wheelHandler = (e) => {
    const eventDir = (e.deltaY >= 0) ? 1 : 0;
    const prevDir = (this.wheelDelta.prev >= 0) ? 1 : 0;

    if(eventDir !== prevDir){
      this.wheelDelta.tot = 0;
    }

    this.wheelDelta.tot += e.deltaY;
    this.wheelDelta.prev = e.deltaY;

    if(this.wheelDelta.tot > SCROLL_WHEEL_THRESHOLD){
      this.postClickHandler(1);
      this.wheelDelta.tot = 0;
    } else if(this.wheelDelta.tot < -SCROLL_WHEEL_THRESHOLD){
      this.postClickHandler(0);
      this.wheelDelta.tot = 0;
    }
  }

  render(){
    const query = "(-webkit-min-device-pixel-ratio: 1.25) and (max-device-width: 2560px), ( min--moz-device-pixel-ratio: 1.25) and (max-device-width: 2560px), ( -o-min-device-pixel-ratio: 1.25/1) and (max-device-width: 2560px), ( min-device-pixel-ratio: 1.25) and (max-device-width: 2560px), ( min-resolution: 200dpi) and (max-device-width: 2560px), ( min-resolution: 1.25dppx) and (max-device-width: 2560px)";

    // const tabletQuery = "(-webkit-min-device-pixel-ratio: 1.25) and (max-device-width: 1024px), ( min--moz-device-pixel-ratio: 1.25) and (max-device-width: 1024px), ( -o-min-device-pixel-ratio: 1.25/1) and (max-device-width: 1024px), ( min-device-pixel-ratio: 1.25) and (max-device-width: 1024px), ( min-resolution: 200dpi) and (max-device-width: 1024px), ( min-resolution: 1.25dppx) and (max-device-width: 1024px)";
    // const mobileQuery = "(-webkit-min-device-pixel-ratio: 1.25) and (max-device-width: 768px), ( min--moz-device-pixel-ratio: 1.25) and (max-device-width: 768px), ( -o-min-device-pixel-ratio: 1.25/1) and (max-device-width: 768px), ( min-device-pixel-ratio: 1.25) and (max-device-width: 768px), ( min-resolution: 200dpi) and (max-device-width: 768px), ( min-resolution: 1.25dppx) and (max-device-width: 768px)";
    // console.log(matchMedia(tabletQuery));\
    const orientationQuery = "(min-device-width:768px) and (max-device-width:1024px) and (orientation: portrait)";
    // console.log(matchMedia(orientationQuery).matches);
    let offset;
    let itemsOffset;
    if(window.innerWidth < 768) {
      offset = 283;
      itemsOffset = 0;
      // console.log("1");
    } else if(window.innerWidth >= 768 && window.innerWidth <= 1024 && matchMedia(orientationQuery).matches){
      offset = 416;
      itemsOffset = (window.innerHeight / 2) - (266 / 2);
      // console.log("2");
    } else if(window.innerWidth > 1024 && window.innerWidth <= 1920  && !matchMedia(query).matches) {
      offset = 670;
      itemsOffset = (window.innerHeight / 2) - (600 / 2);
      // console.log("3");
    } else if(window.innerWidth > 1920 && window.innerWidth <= 2560  && !matchMedia(query).matches) {
      offset = 850;
      itemsOffset = (window.innerHeight / 2) - (600 / 2);
      // console.log("4");
    } else if(matchMedia(query).matches && window.innerWidth > 768 && window.innerWidth <= 2560) {
      offset = 536;
      itemsOffset = (window.innerHeight / 2) - (466 / 2);
      // console.log("5");
    }
    // else if(window.innerWidth > 768 && matchMedia(tabletQuery).matches) {
    //   offset = 416;
    //   itemsOffset = (window.innerHeight / 2) - (266 / 2);
    //   console.log("5");
    // }

    const itemsStyle = {
      transform: `translateY(${itemsOffset}px)`
    }

    let title = {
      en: null,
      nl: null,
      meta: null
    };
    if(this.state.pages[this.state.currPost] > 1){
      title.en = this.props.posts[this.state.currPost].images[this.state.pages[this.state.currPost] - 1].description.description_en;
      title.nl = this.props.posts[this.state.currPost].images[this.state.pages[this.state.currPost] - 1].description.description_nl;
      title.meta = null
    } else if(this.state.pages[this.state.currPost] <= 1){
      title.en = this.props.posts[this.state.currPost].title_en;
      title.nl = this.props.posts[this.state.currPost].title_nl;
      title.meta = this.props.posts[this.state.currPost].meta
    }

    // console.log("state change");
    return(
      <Swipe
        className={styles.swipe}
        onSwipeStart={this.onSwipeStart}
        onSwipeMove={this.onSwipeMove}
        onSwipeEnd={this.onSwipeEnd}>
        <div className={styles.projects} onWheel = {(e) => this.wheelHandler(e)}>
          <div style={itemsStyle} className={styles.items}>
            {
              (this.props.posts || []).map((post, i) => {
                  return <ProjectItem
                    key={post.id}
                    post={post}
                    currPage={this.state.pages.length === this.props.posts.length ? this.state.pages[i] : 0}
                    totPage={Object.keys(this.props.posts[i].images).length}
                    offset={this.state.currPost * -offset}
                    pageClickHandler={this.pageClickHandler}
                    postClickHandler={this.postClickHandler}
                    pid={i}
                    currPost={this.state.currPost}
                    totPosts={this.props.posts.length}
                  />
                })
            }
          </div>
          <FloatingTitle
              currTitleNl={title.nl}
              currTitleEn={title.en}
              meta={title.meta}
              showArrow={this.state.pages[this.state.currPost] === 0}
              showTitle={this.state.pages[this.state.currPost] !== 1 } />
        </div>
      </Swipe>
    );
  }
}

const mapStateToProps = state => ({
  posts: getSelWorks(state)
});

const mapDispatchToProps = (dispatch) => ({
  onFetchPosts: query => dispatch(doFetchSelWorks(query)),
  onSetCategory: category => dispatch(doSetCategory(category)),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Projects));
