import {
  LANGUAGE_SET,
} from '../constants/actionTypes';

const INITIAL_STATE = {
  language: 'nl'
};

const applySetLanguage = ( state, action ) => {
  return(
    {
      language: action.language,
    }
  );
};

function languageReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case LANGUAGE_SET : {
      return applySetLanguage(state, action);
    }
    default: return state;
  }
}

export default languageReducer;
