import { combineReducers } from 'redux';
import postReducer from './post';
import languageReducer from './language';
import categoryReducer from './category';

const rootReducer = combineReducers({
  postState: postReducer,
  langState: languageReducer,
  catState: categoryReducer,
});

export default rootReducer;
