import { takeEvery, all } from 'redux-saga/effects';
import {
  CONTACT_FETCH,
  WORKS_FETCH,
  PROCESS_FETCH,
  NEWS_FETCH,
  CV_FETCH,
  LANDING_PAGE_FETCH,
  SEL_WORKS_FETCH
} from '../constants/actionTypes';
import {
  handleFetchContact,
  handleFetchWorks,
  handleFetchProcess,
  handleFetchNews,
  handleFetchCv,
  handleFetchLandingPage,
  handleFetchSelWorks
} from './post';

function *watchAll() {
  yield all([
    takeEvery(CONTACT_FETCH, handleFetchContact),
    takeEvery(WORKS_FETCH, handleFetchWorks),
    takeEvery(PROCESS_FETCH, handleFetchProcess),
    takeEvery(NEWS_FETCH, handleFetchNews),
    takeEvery(CV_FETCH, handleFetchCv),
    takeEvery(LANDING_PAGE_FETCH, handleFetchLandingPage),
    takeEvery(SEL_WORKS_FETCH, handleFetchSelWorks),
  ])
}

export default watchAll;
