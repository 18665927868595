import React from 'react';
import { Link } from 'react-router-dom';

import styles from './Logo.module.css';
// import { TITLE_NL, TITLE_EN } from '../../../../constants/title';

const Logo = (props) => {
  let logo = null;
  // let subtitle = null;
  switch(props.language) {
    default:
    case 'nl':
      logo = <Link
                to={'/' + props.language + '/selected_work'}
                exact="true">
                <div className={styles.title}>
                  <div className={styles.title1}>M&</div>
                  <div className={styles.title2}> DB</div>
                  <div className={styles.title3}>ARCHITECTEN</div>
                </div>
              </Link>
      // subtitle = <Link
      //             to={'/' + props.language + '/selected_work'}
      //             exact="true">
      //             <div className={styles.subtitle}>ARCHITECTEN</div>
      //           </Link>
      break;
    case 'en':
      logo = <Link
              to={'/' + props.language + '/selected_work'}
              exact="true">
              <div className={styles.title}>
                <div className={styles.title1}>M&</div>
                <div className={styles.title2}>DB</div>
                <div className={styles.title3}>ARCHITECTS</div>
              </div>
            </Link>
      // subtitle = <Link
      //             to={'/' + props.language + '/selected_work'}
      //             exact="true">
      //             <div className={styles.subtitle}>ARCHITECTS</div>
      //           </Link>
      break;
  }

  return(
    <div className={styles.Logo}>
      {logo}
    </div>
  );
}

export default Logo;
