import React from 'react';
import { connect } from 'react-redux';

import styles from './DropDown.module.css';
import MenuItems from '../../../MenuItems/MenuItems';
import { getLanguage } from '../../../../../../selectors/language';
import { getCategory } from '../../../../../../selectors/category';
import Language from '../../../Language/Language';
import SocialMenu from '../../../SocialMenu/SocialMenu';

const DropDown = (props) => {
  return(
    <div className={styles.DropDown}>
      <MenuItems
        language={props.language}
        category={props.category}
        itemClickHandler={props.itemClickHandler} />
      <div className={styles.bottom}>
        <SocialMenu />
        <Language clickHandler={props.langClickHandler} />
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  language: getLanguage(state),
  category: getCategory(state),
});

export default connect(
  mapStateToProps
)(DropDown);
