import React from 'react';

import styles from './HamburgerMenu.module.css';
import DropDown from './DropDown/DropDown';
import HamburgerIcon from './HamburgerIcon/HamburgerIcon';

const HamburgerMenu = (props) => {
  return(
    <div className={styles.wrapper}>
      <div className={styles.HamburgerMenu}>
        <HamburgerIcon isActive={props.isActive} clickHandler={props.clickHandler} />
      </div>
      <div className={styles.dropdown}>
        {
          props.isActive
            ? <DropDown
                langClickHandler={props.langClickHandler}
                itemClickHandler={props.itemClickHandler} />
            : null
        }
      </div>
    </div>
  );
}

export default HamburgerMenu;
