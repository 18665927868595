import React from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Projects from './Projects/Projects';
// import ProjectsSelected from './ProjectsSelected/ProjectsSelected';
import Process from './Process/Process';
import News from './News/News';
import Cv from './Cv/Cv';
import Contact from './Contact/Contact';
import ProjectOverview from './ProjectOverview/ProjectOverview';
import { getLanguage } from '../../../selectors/language';

const Content = (props) => {
  // console.log(props.isEntered);

  // <Route
  //   path="/:language/selected_work"
  //   component={ProjectsSelected} />}
  // />
  // console.log(props.isMobMenuActive);
  return(
    <Switch>
      <Redirect exact from="/" to={'/' + props.language + '/selected_work'} />
      <Route
        path="/:language/selected_work"
        render={(routeProps) => <Projects {...routeProps} isEntered={props.isEntered} isMobMenuActive={props.isMobMenuActive} />}
      />
      <Route
        path="/:language/overview_work"
        component={ProjectOverview}
      />
      <Route
        path="/:language/archive"
        component={Process}
      />
      <Route
        path="/:language/news"
        component={News}
      />
      <Route
        path="/:language/about"
        component={Cv}
      />
      <Route
        path="/:language/contact"
        component={Contact}
      />
    </Switch>
  );
}

const mapStateToProps = state => ({
  language: getLanguage(state),
});

export default withRouter(connect(
  mapStateToProps
)(Content));
