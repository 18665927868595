import {
  CONTACT_ADD,
  CONTACT_FETCH,
  WORKS_ADD,
  WORKS_FETCH,
  PROCESS_ADD,
  PROCESS_FETCH,
  NEWS_ADD,
  NEWS_FETCH,
  CV_ADD,
  CV_FETCH,
  LANDING_PAGE_ADD,
  LANDING_PAGE_FETCH,
  SEL_WORKS_ADD,
  SEL_WORKS_FETCH
} from '../constants/actionTypes';

const doAddContact = contact => ({
  type: CONTACT_ADD,
  contact,
});

const doFetchContact = query => ({
  type: CONTACT_FETCH,
  query
})

const doFetchWorks = query => ({
  type: WORKS_FETCH,
  query
})

const doAddWorks = works => ({
  type: WORKS_ADD,
  works,
});

const doFetchProcess = query => ({
  type: PROCESS_FETCH,
  query
})

const doAddProcess = process => ({
  type: PROCESS_ADD,
  process,
});

const doFetchNews = query => ({
  type: NEWS_FETCH,
  query
})

const doAddNews = news => ({
  type: NEWS_ADD,
  news,
});

const doFetchCv = query => ({
  type: CV_FETCH,
  query
})

const doAddCv = cv => ({
  type: CV_ADD,
  cv,
});

const doFetchLandingPage = query => ({
  type: LANDING_PAGE_FETCH,
  query
})

const doAddLandingPage = landingpage => ({
  type: LANDING_PAGE_ADD,
  landingpage,
});

const doFetchSelWorks = query => ({
  type: SEL_WORKS_FETCH,
  query
})

const doAddSelWorks = selWorks => ({
  type: SEL_WORKS_ADD,
  selWorks,
});

export {
  doAddContact,
  doFetchContact,
  doAddWorks,
  doFetchWorks,
  doAddProcess,
  doFetchProcess,
  doAddNews,
  doFetchNews,
  doAddCv,
  doFetchCv,
  doAddLandingPage,
  doFetchLandingPage,
  doFetchSelWorks,
  doAddSelWorks,
};
