import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import styles from './News.module.css';
import { NEWS_CAT } from '../../../../constants/categories';
import { doFetchNews } from '../../../../actions/post';
import { getNews } from '../../../../selectors/post';
import { doSetCategory } from '../../../../actions/category';
import NewsItem from './NewsItem/NewsItem';
// import Navigation from '../Navigation/Navigation';
import Aux from '../../../../hoc/Aux';

class News extends Component {
  constructor(props){
    super(props);

    this.state = {
      isLoading: true,
      currPost: 0,
    }

    this.itemRefs = [];
  }

  componentDidMount(){
    this.props.onSetCategory(NEWS_CAT);
    this.props.onFetchPosts(NEWS_CAT);
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.posts !== this.props.posts){
      this.setState({
        isLoading: false
      });

      // this.itemRefs = this.props.posts.map(() => {
      //   return React.createRef()
      // });

      // if(this.props.match.isExact){
      //   this.props.history.push({pathname: this.props.match.url + '/' + this.props.posts[this.state.currPost].title.toLowerCase()});
      // }
    }

    // if(prevState.isLoading === true && this.state.isLoading === false){
    //   if(prevProps.match.isExact === false){
    //     const locArr = prevProps.location.pathname.split('/');
    //     const title = locArr[3];
    //     let pid = 0;
    //     this.props.posts.forEach((post, i) => {
    //       if(title === post.title.toLowerCase()){
    //         pid = i;
    //       }
    //     });
    //     // this.itemRefs[pid].current.scrollIntoView();
    //     this.setState({
    //       currPost: pid,
    //     });
    //   }
    // }
  }

  postClickHandler = (dir) => {
    const size = this.props.posts.length;
    let currPost = this.state.currPost;
    if (dir === 0) {
      currPost -= 1;
      currPost = currPost < 0 ? 0 : currPost;
    } else if (dir === 1) {
      currPost += 1;
      currPost = currPost > size - 1 ? size - 1 : currPost;
    }

    this.props.history.push({pathname: this.props.match.url + '/' + this.props.posts[currPost].title.toLowerCase()});
    // this.itemRefs[currPost].current.scrollIntoView({behavior: 'smooth',
    //                                                 block: 'start',
    //                                                 inline: 'center'});

    this.setState({
      currPost: currPost,
    });
  }

  wheelHandler = (e) => {
    if(e.deltaY < 0) {
      this.postClickHandler(0);
    } else {
      this.postClickHandler(1);
    }
  }

  render(){
    return(
      <Aux>
        <div className={styles.news}>
          <div className={styles.items}>
            {
              (this.props.posts || []).map((post, i) => {
                return <NewsItem
                  key={post.id}
                  post={post}
                />
              })
            }
          </div>
        </div>
      </Aux>
    );
  }
}

const mapStateToProps = state => ({
  posts: getNews(state),
});

const mapDispatchToProps = (dispatch) => ({
  onFetchPosts: query => dispatch(doFetchNews(query)),
  onSetCategory: category => dispatch(doSetCategory(category)),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(News));
