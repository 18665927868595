import React from 'react';

import styles from './ProcessItem.module.css';

const Content = (props) => {
  const style = props.post.width > props.post.height
    ? [styles.image, styles.landscape].join(' ')
    : [styles.image, styles.portrait].join(' ');
  return(
    <div className={styles.content}>
      <div className={style}>
        <img src={props.post.url} alt="" />
      </div>
    </div>
  );
}

export default Content;
