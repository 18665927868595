import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import styles from './App.module.css';
import Menu from './Menu/Menu';
import Content from './Content/Content';
import LandingPage from './LandingPage/LandingPage';
import { doSetLanguage } from '../../actions/language';

class App extends Component {
  constructor(props){
    super(props);

    this.contentRef = React.createRef();
    this.lpRef = React.createRef();

    if(this.props.match.isExact && window.innerWidth >= 768) {
      this.state = {
        isEntered: false,
        showEnter: true,
        offset: 0,
        windowHeight: window.innerHeight,
        isMobMenuActive: false,
      };
    } else {
      this.state = {
        isEntered: true,
        showEnter: false,
        offset: 0,
        windowHeight: window.innerHeight,
        isMobMenuActive: false,
      };
    }
  }

  componentDidMount() {
    if(!this.props.match.isExact) {
      const lang = this.props.location.pathname.split('/')[1];
      this.props.onLanguage(lang);
    }

    window.addEventListener("resize", this.windowResize);
    this.scrollElement = document.querySelector('body');
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.isEntered && !prevState.isEntered){
      this.lpRef.current.addEventListener('transitionend', (e) => {this.onTransitionEnd(e)});
    }

  }

  onTransitionEnd = (e) => {
    if(e.propertyName === 'transform'){
      this.setState({
        showEnter: false,
        offset: 0
      });
    }
  }

  langClickHandler = (lang) => {
    this.props.onLanguage(lang);

    const url = this.props.location.pathname.split('/');
    const urlPost = url.slice(2, url.length);
    this.props.history.push({pathname: '/' + [lang, urlPost.join('/')].join('/')});
  }

  enterClickHandler = () => {
    this.setState({
      isEntered: true,
      offset: -100
    });
  }

  mobMenuClickHandler = (isActive) => {
    // console.log(isActive);
    this.setState({
      isMobMenuActive: isActive
    })
  }

  windowResize = (e) => {
    this.setState({
      windowHeight: window.innerHeight,
    });
  }

  render() {
    // console.log(this.state);
    let style
    if(this.state.showEnter){
      style = {
        overflow: 'hidden',
        height: `${this.state.windowHeight}px`
      };
    } else {
      style = {
        overflow: 'visible',
        height: `${this.state.windowHeight}px`
      };
    }

    // if(this.props.isMobMenuActive) {
    //   blurStyle = {
    //     filter: 'blur(3px)'
    //   };
    //   overlayStyle = {
    //     display: 'inherit'
    //   };
    // } else {
    //   blurStyle = {
    //     filter: 'blur(0px)'
    //   };
    //   overlayStyle = {
    //     display: 'none'
    //   };
    // }
    const overlayStyle = (this.state.isMobMenuActive)
      ? {
        display: 'inherit'
      }
      : {
        display: 'none'
      }

    const blurStyle = (this.state.isMobMenuActive)
      ? {
        filter: 'blur(3px)'
      }
      : {
        filter: 'blur(0px)'
      }

    return (
      <div style={style} className={styles.app}>
        <Helmet>
          <meta name="keywords" content="M&DB, M& DB, M en DB, MenDB, Kinderdijk, Werelderfgoed, UNSECO, World heritage, Bezoekerscentrum, Visitor centre, architect van werelderfgoed kinderdijk" />
        </Helmet>
        {
          this.state.showEnter
            ? <LandingPage
                clickHandler={this.enterClickHandler}
                offset={this.state.offset}
                ref_={this.lpRef}
              />
            : null
        }
        <Menu
          langClickHandler={this.langClickHandler}
          mobMenuClickHandler={this.mobMenuClickHandler}
          isEntered={this.state.isEntered}
          offset={this.state.offset}
          shouldAnim={this.state.showEnter}
        >
          <div style={blurStyle} className={styles.contentWrapper}>
            <Content isEntered={this.state.isEntered} isMobMenuActive={this.state.isMobMenuActive} />
            <div style={overlayStyle} className={styles.overlay} />
          </div>
        </Menu>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onLanguage: language => dispatch(doSetLanguage(language)),
});

export default withRouter(connect(
  null,
  mapDispatchToProps
)(App));
